<template>
  <b-modal
    id="edit-modal"
    ref="edit-modal"
    no-close-on-backdrop
    ok-title="確認"
    centered
    header-bg-variant="primary"
    ok-only
  >
    <template #modal-title>
      <h4 class="m-0 model-header">
        編輯網域商
      </h4>
    </template>

    <validation-observer
      v-if="!initBusy"
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <!-- Form -->
      <b-form
        ref="refDataTypeTable"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <!-- 名稱 -->
        <validation-provider
          #default="validationContext"
          name="名稱"
          rules="required"
        >
          <b-form-group
            label="名稱"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="domainAgent.agent"
              :state="getValidationState(validationContext)"
              trim
              placeholder="請輸入網域商名稱"
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-form>

      <div>
        <!-- 基本參數 -->
        <div class="mb-1">
          <div class="d-flex align-items-center justify-content-between font-weight-bold">
            <div>
              <feather-icon
                icon="BoxIcon"
                class="mr-50"
                style="margin-top: -3px"
              />

              <span style="font-size: 16px">參數設定</span>
            </div>

            <div>
              <div
                v-if="domainAgent.rules.length > 0"
                class="actions-link-btn mr-25"
                @click="cleanAllRules"
              >
                <b-img
                  v-b-tooltip.hover.focus.v-secondary
                  title="一鍵清空"
                  :src="require('@/assets/images/pages/ui/blue-line/trash-bin.svg')"
                  class="actions-link-btn-image"
                  width="14"
                  rounded
                />
              </div>
            </div>
          </div>

          <hr class="mt-50">

          <draggable
            tag="ul"
            :list="domainAgent.rules"
            class="list-group"
          >
            <li
              v-for="(element, index) in domainAgent.rules"
              :key="`rules-${index}`"
              class="list-group-item"
            >
              <div>
                <div class="d-flex justify-content-between align-items-center">
                  <div class="d-flex">
                    <feather-icon
                      icon="AlignJustifyIcon"
                      size="20"
                      class="cursor-pointer mr-1"
                    />

                    <div>
                      <!-- 名稱 -->
                      <div
                        class="font-weight-bold cursor-pointer"
                        @click="selectedEditRow(element, index)"
                      >
                        <span
                          v-if="element.required"
                          class="required-key"
                        >
                          *
                        </span>{{ element.name }}
                        <small>( {{ element.key }} )</small>
                      </div>

                      <!-- 參數類型 -->
                      <div v-if="element.type">
                        <small class="text-muted d-flex">
                          類型 :
                          <div class="item-description ml-50">{{ resolveRuleMode(element.type).label }}</div>
                        </small>
                      </div>

                      <!-- 預設 -->
                      <div v-if="element.options.default">
                        <small class="text-muted d-flex">
                          預設 :
                          <div class="item-description ml-50">{{ element.options.default }}</div>
                        </small>
                      </div>

                      <!-- 參數範圍（字） -->
                      <div v-if="element.options.min || element.options.max">
                        <small class="text-muted d-flex">
                          參數範圍 :
                          <div class="item-description ml-50">{{ element.options.min ? element.options.min : 'null' }} ~ {{ element.options.max ? element.options.max : 'null' }}</div>
                        </small>
                      </div>

                      <!-- 正規表示式 -->
                      <div v-if="element.options.rule">
                        <small class="text-muted d-flex">
                          正規表示式 :
                          <div class="item-description ml-50">{{ element.options.rule }}</div>
                        </small>
                      </div>

                      <!-- 時間格式 -->
                      <div v-if="element.options.format">
                        <small class="text-muted d-flex">
                          時間格式 :
                          <div
                            v-if="element.options.format !== '自訂時間'"
                            class="item-description ml-50"
                          >
                            {{ element.options.format }}
                          </div>

                          <div
                            v-else
                            class="item-description ml-50"
                          >
                            {{ element.options.input }}
                          </div>
                        </small>
                      </div>

                      <!-- 串接符號 -->
                      <div v-if="element.options.delimiter">
                        <small class="text-muted d-flex">
                          串接符號 :
                          <div class="item-description ml-50">{{ element.options.delimiter }}</div>
                        </small>
                      </div>

                    </div>
                  </div>

                  <div class="d-flex text-nowrap align-items-center">
                    <div
                      class="actions-link-btn mr-25"
                      @click="selectedEditRow(element, index)"
                    >
                      <b-img
                        v-b-tooltip.hover.focus.v-secondary
                        title="編輯"
                        :src="require('@/assets/images/pages/ui/blue-line/pen.svg')"
                        class="actions-link-btn-image"
                        rounded
                      />
                    </div>

                    <div
                      class="actions-link-btn"
                      @click="removeItem(element, index)"
                    >
                      <b-img
                        v-b-tooltip.hover.focus.v-secondary
                        title="移除"
                        :src="require('@/assets/images/pages/ui/blue-line/close.svg')"
                        class="actions-link-btn-image"
                        rounded
                      />
                    </div>
                  </div>
                </div>

              </div>
            </li>
          </draggable>
        </div>

        <!-- 新增參數 -->
        <div class="text-center">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            size="sm"
            @click="addNewItem"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-25"
            />
            <span>新增參數</span>
          </b-button>
        </div>

        <!-- 規則設定 -->
        <domain-rule
          ref="domainRuleEditModal"
          domain-rule-id="domain-rule-edit-modal"
          @edit-rule="editDomainData"
        />
      </div>
    </validation-observer>

    <div
      v-else
      class="loading-area"
    >
      <b-img
        :src="$store.state.app.themeImages.loadingImg"
        rounded
        height="60"
        width="60"
      />
    </div>

    <!-- {{ domainAgent }} -->

    <template #modal-footer>
      <div class="w-100 text-right">
        <b-button
          variant="primary"
          :disabled="isBusy"
          @click="handleOk"
        >
          <span v-if="!isBusy">確認</span>

          <b-spinner
            v-else
            small
          />
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { required } from '@validations'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
  BButton, VBTooltip, BImg, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BSpinner,
} from 'bootstrap-vue'
import draggable from 'vuedraggable'
import Ripple from 'vue-ripple-directive'
import formValidation from '@core/comp-functions/forms/form-validation'
import { useSwalToast, useAlert } from '@/libs/mixins/index'
import DomainRule from './components/DomainRule.vue'
import { useDomainSetting, useDomainAgent } from '../useDomain'

export default {
  components: {
    BButton,
    BForm,
    BImg,
    draggable,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BSpinner,

    ValidationProvider,
    ValidationObserver,
    DomainRule,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast],
  props: {
    domainRuleItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isBusy: false,
      required,
      selectedIndex: null,
    }
  },
  methods: {
    // (編輯)參數設定
    editDomainData(item) {
      this.domainAgent.rules[this.selectedIndex].key = item.key
      this.domainAgent.rules[this.selectedIndex].name = item.name
      this.domainAgent.rules[this.selectedIndex].required = item.required
      this.domainAgent.rules[this.selectedIndex].options = item.options
      this.domainAgent.rules[this.selectedIndex].type = item.type
    },

    // (觸發)編輯規則
    selectedEditRow(item, index) {
      if (this.isBusy) return
      this.selectedIndex = index
      setTimeout(() => { this.$refs.domainRuleEditModal.getData(item) }, 200)
    },

    // 新增
    addNewItem() {
      if (this.isBusy) return
      const resolveData = JSON.parse(JSON.stringify(this.blankSetting))
      const index = this.domainAgent.rules.length + 1
      resolveData.name = `參數${index}`
      resolveData.key = `key_${index}`
      this.domainAgent.rules.push(resolveData)
    },

    // (移除)所有規則
    cleanAllRules() {
      if (this.isBusy) return
      this.$swal({
        title: '刪除所有規則',
        text: '你確定要一鍵清除所有規則設定嗎?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.domainAgent.rules = []
        }
      })
    },

    // 移除
    removeItem(item, index) {
      if (this.isBusy) return
      this.$swal({
        title: '刪除參數',
        text: `你確定要刪除參數 ${item.name} 嗎?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.domainAgent.rules.splice(index, 1)
        }
      })
    },

    // 按下彈窗確認
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      if (this.isBusy) return
      this.isBusy = true
      this.$refs.refFormObserver.validate()
        .then(success => {
          if (success) {
            this.setDomainAgentUpdate({
              id: this.domainAgent.id,
              data: {
                ...this.domainAgent,
              },
            })
              .then(response => {
                this.useAlertToast(response.data.success, response.data.message)
                this.isBusy = false
                this.$emit('refetch-data')
                this.resetDomainData()
                this.$nextTick(() => {
                  this.$bvModal.hide('edit-modal')
                })
              })
              .catch(error => {
                this.useHttpCodeAlert(error.response)
                this.isBusy = false
              })
          } else {
            this.useAlertToast(false, '資料填寫不完全')
            this.isBusy = false
          }
        })
    },

    // (獲取)資料
    getDomainAgentData() {
      this.initBusy = true
      this.$bvModal.show('edit-modal')
      this.getDomainAgent({
        id: this.domainRuleItem.id,
      })
        .then(response => {
          const { data } = response.data
          const resolveData = this.syncObject(this.blankDomainAgent, data)
          if (data.rules && data.rules.length > 0) {
            resolveData.rules = data.rules.map(item => {
              const resolveItem = this.syncObject(this.blankSetting, item)
              if (Array.isArray(item.options) && item.options.length === 0) resolveItem.options = {}
              return resolveItem
            })
          }
          this.domainAgent = resolveData
          this.initBusy = false
        })
        .catch(error => {
          this.useHttpCodeAlert(error.response)
        })
    },
  },
  setup() {
    localize('tw')

    const {
      syncObject,
      resolveRuleMode,
    } = useDomainSetting()

    const {
      initBusy,
      domainAgent,
      blankSetting,
      getDomainAgent,
      setDomainAgentUpdate,
    } = useDomainAgent()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    const blankDomainAgent = {
      id: null,
      agent: null,
      rules: [],
    }

    const resetDomainData = () => {
      domainAgent.value = JSON.parse(JSON.stringify(blankDomainAgent))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetDomainData)

    return {
      initBusy,
      domainAgent,
      blankDomainAgent,
      blankSetting,
      syncObject,
      useAlertToast,
      useHttpCodeAlert,

      getDomainAgent,
      setDomainAgentUpdate,

      resetDomainData,
      refFormObserver,
      getValidationState,
      resetForm,
      resolveRuleMode,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.model-header {
  color: white;
  padding: 5px 0;
}

.item-description {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 200px;
}

.loading-area {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 300px;
}
.required-key {
  color: red;
}
</style>
